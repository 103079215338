<template>
    <Header></Header>
    <div class="container min-height padding-top-default">
        <div class="faq faq-page z-index">
            <div class="row pb-4">
                <h1 class="faq-title">Лицензия</h1>
                <p class="faq-subtitle pb-4">Наша лицензия</p>
                <p class="text">Компания Bitok.kg гордится тем, что соответствует всем законодательным требованиям и стандартам безопасности в области криптовалютных операций. Мы обладаем всеми необходимыми лицензиями и сертификатами, подтверждающими нашу деятельность и обеспечивающими надежность и безопасность для наших клиентов.</p>
                <a :href="License" target="_blank">
                    <img :src="License" alt="Bitok LLC" class="license-img">
                </a>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import License from '../../assets/files/license.jpg'

export default {
    data() {
        return {
            License: License
        }
    },
    components: {
        Header,
        Footer
    }
}
</script>