<template>
    <div>
        <h1 class="dashboard-title">Мои кошельки</h1>
        <div class="row">
            <div class="col-12 col-md-12 col-lg-12 col-xl-8">
                <template v-for="(balance, index) in balances" :key="index">
                    <div class="balance-wrap mb-3">
                        <div class="balance-body">
                            <img :src="balance.logo" :alt="balance.name">
                            <div class="balance-text">
                                <h3>
                                    <b>{{ balance.currency }}</b>
                                    <span>{{ balance.balance }}</span>
                                </h3>
                                <h2>
                                    <b>{{ balance.name }}</b>
                                    <span v-if="balance.rate" :class="String(balance.difference).search('-') !== -1 ? 'text-danger' : 'text-success'">${{ balance.currency == 'USDT' ? '1.00' : balance.rate }}</span>
                                </h2>
                            </div>
                        </div>
                        <div class="balance-footer">
                            <router-link :to="'/dashboard/refill/cryptocurrency?currency=' + balance.currency" class="button block" v-if="balance.can_deposit === true">Ввод</router-link>
                            <a class="button block disabled" v-else>Ввод</a>
                            <router-link :to="'/dashboard/withdraw/' + balance.currency" class="button block" v-if="balance.can_withdraw === true">Вывод</router-link>
                            <a class="button block disabled" v-else>Вывод</a>
                        </div>
                    </div>
                </template>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-4 md-none">
                <div class="row">
                    <div class="col-lg-12 col-xl-12">
                        <div class="dashboard-personal-wrap mt-0" v-if="profile">
                            <h1 class="title">Верификация аккаунта</h1>
                            <div class="profile-info">
                                <div class="photo-wrap">
                                    <img :src="profile.avatar + '?v=1.0.0'" :alt="profile.email">
                                </div>
                                <div>
                                    <b>{{ profile.firstname + ' ' + profile.lastname }}</b>
                                    <span v-if="profile.verification.value == 1" class="text-danger">{{ profile.verification.name }}</span>
                                    <span v-if="profile.verification.value == 2" class="text-success">{{ profile.verification.name }}</span>
                                    <span v-if="profile.verification.value == 3" class="text-warning">{{ profile.verification.name }}</span>
                                    <span v-if="profile.verification.value == 4" class="text-danger">{{ profile.verification.name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-12">
                        <div class="dashboard-personal-wrap" v-if="profile">
                            <h1 class="title">Лимиты за текущий день</h1>
                            <div class="profile-info-column refill">
                                <div>
                                    <span>Пополнение</span>
                                    <b>{{ profile.limits.refill }} USDT</b>
                                </div>
                            </div>
                            <div class="profile-info-column buy">
                                <div>
                                    <span>Покупка</span>
                                    <b>{{ profile.limits.buy }} USDT</b>
                                </div>
                            </div>
                            <div class="profile-info-column sell">
                                <div>
                                    <span>Продажа</span>
                                    <b>{{ profile.limits.sell }} USDT</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-12">
                        <div class="dashboard-personal-wrap" v-if="profile">
                            <h1 class="title">Персональные данные</h1>
                            <div class="profile-info-column calendar">
                                <div>
                                    <span>Дата регистрации</span>
                                    <b>{{ profile.date_register }}</b>
                                </div>
                            </div>
                            <div class="profile-info-column exit">
                                <div>
                                    <span>Последний вход</span>
                                    <b>{{ profile.last_access }}</b>
                                </div>
                            </div>
                            <div class="profile-info-column email">
                                <div>
                                    <span>Электронная почта</span>
                                    <b>{{ profile.email }}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-window" v-if="modalStatus">
        <div class="modal-close"></div>
        <div class="modal-content padding-none">
            <div class="p-3">
                <h1 class="modal-title">Опрос</h1>
                <span class="modal-subtitle mb-0">Пожалуйста, ответьте на вопросы</span>
            </div>
            <form class="form position-relative z-index" autocomplete="off">
                <SurveyComponent :model="survey" @onSubmit="handleSurvey" />
                <div class="p-3" v-if="surveyRetryStatus">
                    <button class="button block lg" v-on:click="createSurvey">Попробовать еще раз</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { Model } from 'survey-core'
import 'survey-core/defaultV2.min.css'

export default {
    data() {
        return {
            modalStatus: false,
            surveyRetryStatus: false,
            survey: '',
        }
    },
    created() {
        this.$store.dispatch('getCurrencies')
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        balances() {
            return this.$store.getters.getCurrencies
        },
        profile() {
            return this.$store.getters.getProfile
        }
    },
    watch: {
        profile() {
            if(this.profile.survey_status === false) {
                if(localStorage.getItem('survey') !== 'passed') {
                    this.modalStatus = true
                    this.createSurvey()
                }
            }
        }
    },
    methods: {
        createSurvey() {
            const surveyJson = {
                goNextPageAutomatic: false,
                firstPageIsStarted: true,
                pages: [
                    {
                        elements: [{
                            "type": "radiogroup",
                            "name": "survey1",
                            "title": "Виртуальный актив может быть высоковолатильным активом при использовании которого могут возникать существенные потери из-за изменений курса",
                            "choices": ["Да", "Нет"],
                            "correctAnswer": "Да",
                            "isRequired": true,
                            "separateSpecialChoices": true
                        }]
                    },
                    {
                        elements: [{
                            "type": "radiogroup",
                            "name": "survey2",
                            "title": "Виртуальный актив не обеспечен и не гарантирован государством Кыргызской Республики",
                            "choices": ["Да", "Нет"],
                            "correctAnswer": "Да",
                            "isRequired": true,
                            "separateSpecialChoices": true
                        }]
                    },
                    {
                        elements: [{
                            "type": "radiogroup",
                            "name": "survey3",
                            "title": "В случае противоправных действий и технических сбоев пользователь может лишиться всех виртуальных активов и иных объектов гражданских прав, переданных в обмен на виртуальные активы",
                            "choices": ["Да", "Нет"],
                            "correctAnswer": "Да",
                            "isRequired": true,
                            "separateSpecialChoices": true
                        }]
                    },
                    {
                        elements: [{
                            "type": "radiogroup",
                            "name": "survey4",
                            "title": "Виртуальный актив не является законным средством платежа в Кыргызской Республике",
                            "choices": ["Да", "Нет"],
                            "correctAnswer": "Да",
                            "isRequired": true,
                            "separateSpecialChoices": true
                        }]
                    },
                    {
                        elements: [{
                            "type": "radiogroup",
                            "name": "survey5",
                            "title": "У некоторых виртуальных активов отсутствует централизованный орган, ответственный за проведение операций с ними, что делает невозможным реализацию процессов отмены и возврата",
                            "choices": ["Да", "Нет"],
                            "correctAnswer": "Да",
                            "isRequired": true,
                            "separateSpecialChoices": true
                        }]
                    }
                ],
                showQuestionNumbers: false,
                completedHtml: "Вы ответили {correctAnswers} из {questionCount} правильных ответов.",
                completedHtmlOnCondition: [
                    {
                        "expression": "{correctAnswers} == 0",
                        "html": "К сожалению, ни один из ваших ответов не является правильным. Пожалуйста, попробуйте еще раз."
                    }
                ]
            }
            this.surveyRetryStatus = false
            this.survey = new Model(surveyJson)
            this.survey.onComplete.add((sender) => {
                this.handleSurvey(sender.data)
            })
        },
        handleSurvey(data) {
            Object.entries(data).forEach((item) => {
                if(item[1] !== 'Да') {
                    this.surveyRetryStatus = true
                } else {
                    this.$store.dispatch('updateSurveyStatus')
                    localStorage.setItem('survey', 'passed')
                    this.modalStatus = false
                }
            })
        }
    }
}
</script>