<template>
    <div class="row m-order" v-if="profile">
        <template v-if="profile.verification.value !== '2'">
            <span class="icon-text times" v-if="profile.verification.value == '4'">{{ profile.verification.name }}</span>
            <div class="iframe-verification">
                <iframe :src="'https://api.bitok.kg/ru/sumsub/widget?token=' + token" allow="camera; microphone; geolocation"></iframe>
            </div>
        </template>
    </div>
    <template v-if="profile && profile.verification.value == '2'">
        <div class="dashboard-personal-wrap">
            <span class="icon-text my-4 check">{{ profile.verification.name }}</span>
        </div>
    </template>
    <Select :data="SelectData"></Select>
</template>

<script>
import Select from '@/components/Account/Select'

export default {
    data() {
        return {
            token: '',
            firstname: '',
            lastname: '',
            dateBirth: '',
            passportNumber: '',
            inn: '',
            city: '',
            email: '',
            phone: '',
            SelectData: {
                title: 'Выберите страну',
                list: '',
                ListName: 'name',
                ListValue: 'code'
            },
            passportPhoto: null,
            passportPhotoPreview: '',
            addressPhoto: null,
            addressPhotoPreview: ''
        }
    },
    components: {
        Select
    },
    mounted() {
        if (this.profile) {
            this.firstname = this.profile.firstname
            this.lastname = this.profile.lastname
        }
        this.token = localStorage.getItem('token')
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        selectName() {
            return this.$store.getters.selectName
        },
        profile() {
            return this.$store.getters.getProfile
        },
        country() {
            return (this.$store.getters.country)
                ? this.$store.getters.country
                : this.$store.dispatch('getCountry')
        }
    },
    watch: {
        profile() {
            if (this.profile) {
                this.firstname = this.profile.firstname
                this.lastname = this.profile.lastname
            }
            console.log(this.profile.verification.value)
        },
        country() {
            this.SelectData.list = this.country
        }
    },
    methods: {
        showSelectModal() {
            this.$store.dispatch('setModalStatus', true)
        },
        passportPreview(e) {
            this.passportPhoto = e.target.files[0]
            this.passportPhotoPreview = URL.createObjectURL(this.passportPhoto)
        },
        addressPreview(e) {
            this.addressPhoto = e.target.files[0]
            this.addressPhotoPreview = URL.createObjectURL(this.addressPhoto)
        },
        onsubmit() {
            const formData = new FormData()
            formData.append('firstname', this.firstname)
            formData.append('lastname', this.lastname)
            formData.append('date_birth', this.dateBirth)
            formData.append('passport_id', this.passportNumber)
            formData.append('inn', this.inn)
            formData.append('country', this.$store.getters.selectValue)
            formData.append('city', this.city)
            formData.append('address', this.address)
            formData.append('photo_passport', this.passportPhoto)
            formData.append('photo_address', this.addressPhoto)
            this.$store.dispatch('verification', formData)
        }
    }
}
</script>