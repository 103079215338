<template>
    <section class="news">
        <div class="container">
            <h1 class="news-wrap-title">Оставайтесь в курсе последних новостей</h1>
            <p class="news-wrap-subtitle">Узнайте все о криптовалюте, чтобы начать инвестировать</p>
            <div class="row" v-if="news">
                <div class="col-12 col-md-6" v-if="news.news[1]">
                    <router-link :to="'/news/' + news.news[1].id" class="news-card news-main">
                        <img :src="news.news[1].photo" :alt="news.news[1].name">
                        <h1 class="news-title">{{ news.news[1].name }}</h1>
                        <p class="news-text">{{ news.news[1].body }}</p>
                    </router-link>
                </div>
                <div class="col-12 col-md-6 col-lg-3" v-if="news.news[2]">
                    <router-link :to="'/news/' + news.news[2].id" class="news-card news-second">
                        <img :src="news.news[2].photo" :alt="news.news[2].name">
                        <span>{{ news.news[2].name }}</span>
                        <p>{{ news.news[2].body }}</p>
                    </router-link>
                </div>
                <div class="col-12 col-md-6 col-lg-3" v-if="news.news[3]">
                    <router-link :to="'/news/' + news.news[3].id" class="news-card news-second">
                        <img :src="news.news[3].photo" :alt="news.news[3].name">
                        <span>{{ news.news[3].name }}</span>
                        <p>{{ news.news[3].body }}</p>
                    </router-link>
                    <router-link to="/news" class="button d-block mt-4 py-3">Все новости</router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    computed: {
        news() {
            return this.$store.getters.getMainData
        }
    }
}
</script>