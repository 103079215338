<template>
  <Notification></Notification>
  <Modal></Modal>
  <router-view></router-view>
</template>

<script>
import Notification from '@/components/Notification.vue'
import Modal from '@/components/Modal.vue'
export default {
  name: 'App',
  components: {
    Notification,
    Modal
  },
  created() {
    const self = this
    setInterval(function () {
      if (localStorage.getItem('isAuth') && Math.round(Date.now() / 1000) > localStorage.getItem('expires')) {
        self.$store.dispatch('logout')
        self.$router.push('/?page=login&SessionExpired=true')
      }
      if (!navigator.onLine || window.addEventListener('offline', null)) {
        self.$store.dispatch('notification', { message: 'Отсутствует подключение к интернету' })
      }
    }, 3000)
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        document.title = 'Купить и продать криптовалюту в Бишкеке. Обмен Биткоина, USDT и др. на доллары | Bitok.kg'
      }
    },
  }
}
</script>
