<template>
    <section class="features">
        <div class="container">
            <div class="row features-block">
                <div class="col-12 col-md-12 col-lg-4">
                    <span>Доверенная криптоплатформа для продажи, покупки и обмена</span>
                </div>
                <div class="col-12 col-md-12 col-lg-7">
                    <p>Одна из лучших платформ обмена криптовалют.<br>Он имеет самую быструю комиссию за транзакцию 0, безопасность данных, поддержку покупки криптовалюты с помощью кредитной или дебетовой карты и другие преимущества</p>
                </div>
            </div>
            <div class="row features-container">
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="features-wrap">
                        <div class="features-title">
                            <i class="features-icon x-icon-free"></i>
                            <span class="features-name">Нет комиссии</span>
                        </div>
                        <p class="features-text">Вы можете совершать транзакции, такие как покупка, продажа, обмен и обмен криптовалюты без комиссии.</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="features-wrap">
                        <div class="features-title">
                            <i class="features-icon x-icon-security"></i>
                            <span class="features-name">Безопасность данных</span>
                        </div>
                        <p class="features-text">Защита данных с автоматическим резервным копированием, восстановлением и репликацией в облаке AQU.</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="features-wrap">
                        <div class="features-title">
                            <i class="features-icon x-icon-card"></i>
                            <span class="features-name">Поддержка кредитной и дебетовой карты</span>
                        </div>
                        <p class="features-text">У нас есть новая функция, которая поддерживает все крипто-транзакции с кредитной и дебетовой картой.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>