<template>
    <h1 class="dashboard-title justify-content-between">
        <span>Вывод средств</span>
        <div>
            <input type="text" name="search" class="form-input" v-model="searchValue" placeholder="Поиск" autocomplete="off">
        </div>
    </h1>
    <div class="dashboard-list">
        <div class="row">
            <template v-for="item in list" :key="item">
                <div class="col-12 col-md-6 col-lg-6 col-xl-4" v-if="item.can_withdraw === true && search(item)">
                    <router-link :to="'/dashboard/withdraw/' + item.currency" class="list-item darks">
                        <img :src="item.logo" :alt="item.name">
                        <div class="list-item-wrap">
                            <div>
                                <b>{{ item.currency }}</b>
                                <span>{{ item.balance }}</span>
                            </div>
                            <small class="list-item-button d-none"></small>
                        </div>
                    </router-link>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            searchValue: ''
        }
    },
    computed: {
        list() {
            return this.$store.getters.getCurrencies
        }
    },
    methods: {
        search(item) {
            return (item.currency.toLowerCase().search(this.searchValue.toLowerCase()) !== -1 || item.name.toLowerCase().search(this.searchValue.toLowerCase()) !== -1) ? true : false
        }
    }
}
</script>