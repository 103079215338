import router from "@/router"

export default {
    state: {
        paymentServices: '',
        paymentServiceDetail: '',
        paymentConfirm: ''
    },
    mutations: {
        setPaymentServices(state, payload) {
            state.paymentServices = payload.list
        },
        setPaymentDetail(state, payload) {
            state.paymentServiceDetail = payload.detail
        },
        setPaymentConfirm(state, payload) {
            state.paymentConfirm = payload.confirm
        }
    },
    actions: {
        async getPaymentServices({dispatch, commit}, categoryId = '') {
            dispatch('sendRequest', {method: 'GET', endpoint: '/payment-services/list/' + categoryId})
            .then(response => response.json())
            .then(data => {
                commit('setPaymentServices', data)
            })
        },
        async getPaymentDetail({dispatch, commit}, paymentId) {
            dispatch('sendRequest', {method: 'GET', endpoint: '/payment-services/detail/' + paymentId})
            .then(response => response.json())
            .then(data => {
                commit('setPaymentDetail', data)
            })
        },
        async createPayment({dispatch, commit}, formData) {
            dispatch('sendFileRequest', {method: 'POST', endpoint: '/payment-services/create', formData})
            .then(response => response.data)
            .then(data => {
                if(data.response === true) {
                    commit('setPaymentConfirm', data)
                } else {
                    commit('notification', {message: data.messages})
                }
            })
            .catch((error) => {
                commit('notification', {message: error.response.data.messages})
            })
        },
        async confirmPayment({dispatch, commit}, data) {
            dispatch('sendRequest', {method: 'POST', endpoint: '/payment-services/confirm/' + data.guid, data})
            .then(response => response.json())
            .then(data => {
                if(data.response === true) {
                    dispatch('getCurrencies')
                    router.push('/dashboard/operations')
                } else {
                    if(data.security === '2fa') {
                        commit('twofactor', true)
                    }
                    commit('notification', {message: data.messages})
                }
            })
        }
    },
    getters: {
        getPaymentServices(state) {
            return state.paymentServices
        },
        getPaymentDetail(state) {
            return state.paymentServiceDetail
        },
        getPaymentConfirm(state) {
            return state.paymentConfirm
        }
    }
}