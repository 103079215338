<template>
    <div class="dashboard-wrap">
        <h1 class="dashboard-title">Оплата услуг — {{ detail.category?.title }} — {{ detail?.name }}</h1>
        <div class="row">
            <div class="col-12 col-md-12 col-lg-12 col-xl-8">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="dashboard-personal-wrap ellipse">
                            <div class="profile-info-column mt-0 refill">
                                <div>
                                    <span>Минимальная сумма</span>
                                    <b>{{ detail?.min_sum }} <u>C</u></b>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="dashboard-personal-wrap ellipse">
                            <div class="profile-info-column mt-0 refill">
                                <div>
                                    <span>Максимальная сумма</span>
                                    <b>{{ detail?.max_sum }} <u>C</u></b>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="dashboard-personal-wrap ellipse">
                            <div class="profile-info-column mt-0 refill">
                                <div>
                                    <span>Комиссия</span>
                                    <b class="d-flex">{{ detail?.commission }}% <div class="px-1" v-if="detail?.addination_commission > 0">+ {{ detail?.addination_commission }} <u>C</u></div></b>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="dashboard-personal-wrap ellipse">
                            <div class="profile-info-column mt-0 refill">
                                <div>
                                    <span>Курс</span>
                                    <b>1.00 USDT = {{ detail?.rate }} <u>C</u></b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dashboard-personal-wrap ellipse ellipse-after">
                    <form v-on:submit.prevent="onsubmit" class="form position-relative z-index" autocomplete="off">
                        <label class="form-label d-flex justify-content-between">Сумма к списанию <span><span class="md-none">Баланс:</span> <b class="max-balance" v-on:click="setMaxAmount">{{ balance }}</b> USDT</span></label>
                        <div class="form-group mb-4 position-relative">
                            <input type="number" name="amount" class="form-input" step=".01" placeholder="Введите сумму" v-model="amount" @input="calculate" required :disabled="loading">
                            <span class="form-icon icon-max-amount ease" v-on:click="setMaxAmount"></span>
                        </div>
                        <label class="form-label">Сумма к зачислению</label>
                        <div class="form-group">
                            <input type="number" name="quantity" class="form-input" placeholder="Сумма к зачислению" v-model="quantity" disabled>
                        </div>
                        <template v-if="!detail?.fields">
                            <label class="form-label">Введите реквизит</label>
                            <div class="form-group mb-4 position-relative">
                                <input type="text" name="requisite" class="form-input" :placeholder="detail?.description ? detail?.description : 'Введите реквизит'" v-model="requisite" required :disabled="loading">
                                <span class="form-icon icon-paste ease" v-on:click="paste"></span>
                            </div>
                        </template>
                        <template v-else>
                            <div v-for="(field, index) in detail?.fields" :key="index">
                                <label class="form-label">{{ field.Name }}</label>
                                <div class="form-group mb-4" v-if="!field.Dictionary">
                                    <input type="text" :name="'fields['+field.Field+']'" class="form-input" :placeholder="index === 0 ? detail?.description : field?.Name" required :disabled="loading">
                                </div>
                                <div class="form-group mb-4" v-else>
                                    <select :name="'fields['+field.Field+']'" class="form-input" required :disabled="loading">
                                        <option value="" disabled selected>{{ field?.Name }}</option>
                                        <option v-for="option in splitter(field.Dictionary, ';')" :key="option" :value="splitter(option)[0]">{{ splitter(option)[1] }}</option>
                                    </select>
                                </div>
                            </div>
                        </template>
                        <button class="button block lg mt-4" :disabled="loading" type="submit">Продолжить</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-window" v-if="modalStatus">
        <div class="modal-close" v-on:click="closeModal"></div>
        <form class="modal-content" v-on:submit.prevent="confirmPayment" autocomplete="off">
            <h1 class="modal-title text-center mb-3">Подтверждение платежа</h1>
            <div class="dashboard-table px-0">
                <div class="dashboard-table-row table-row-v2">
                    <div class="dashboard-table-column">Сумма к списанию</div>
                    <div class="dashboard-table-column">{{ confirm?.credit }} USDT</div>
                </div>
                <div class="dashboard-table-row table-row-v2">
                    <div class="dashboard-table-column">Сумма к зачислению</div>
                    <div class="dashboard-table-column">{{ confirm?.amount }} KGS</div>
                </div>
                <div class="dashboard-table-row table-row-v2">
                    <div class="dashboard-table-column">Комиссия</div>
                    <div class="dashboard-table-column">{{ confirm?.commission }} KGS</div>
                </div>
                <div class="dashboard-table-row table-row-v2 justify-content-center">
                    <div class="dashboard-table-column text-wrap text-center">{{ confirm?.description }}</div>
                </div>
            </div>
            <template v-if="twofactor">
                <label class="form-label mt-3">Google 2Fa Authenticator</label>
                <div class="form-group">
                    <input type="number" class="form-input" ref="code" placeholder="Введите защитный код" :disabled="loading" required>
                </div>
            </template>
            <button class="button block lg" type="submit" :disabled="loading">Подтвердить</button>
        </form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            modalStatus: false,
            serviceId: '',
            requisite: '',
            quantity: '',
            balance: '',
            amount: ''
        }
    },
    mounted() {
        this.serviceId = this.category = this.$router.currentRoute.value.params.id
        this.$store.dispatch('getPaymentDetail', this.serviceId)
        if(this.$store.getters.getCurrencies) {
            this.balance = this.$store.getters.getCurrencies.USDT.balance
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        currencies() {
            return (this.$store.getters.getCurrencies)
            ? this.$store.getters.getCurrencies.USDT
            : this.$store.dispatch('getCurrencies')
        },
        detail() {
            return this.$store.getters.getPaymentDetail
        },
        confirm() {
            return this.$store.getters.getPaymentConfirm
        },
        twofactor() {
            return this.$store.getters.twofactor
        }
    },
    watch: {
        amount() {
            this.amount = this.amount < 0 ? 0 : this.amount
        },
        currencies() {
            this.balance = this.currencies.balance
        },
        confirm() {
            this.showModal()
        }
    },
    methods: {
        async paste() {
            const text = await navigator.clipboard.readText()
            this.requisite = text
        },
        filterCurrency(list, currency) {
            return list.find((el) => { return el.currency == currency })
        },
        showModal() {
            this.$store.dispatch('twofactor', false)
            this.modalStatus = true
        },
        closeModal() {
            this.modalStatus = false
        },
        notification(message, type) {
            this.$store.dispatch('notification', { message, type })
        },
        numberFormat(number, float) {
            var string, result
            if(String(number).includes('.')) {
                string = String(number).split('.')
                result = (float > 0) ? string[0] +'.'+ string[1].substring(0, float) : string[0]
            } else {
                result = number
            }
            return result
        },
        setMaxAmount() {
            this.amount = this.balance
            this.calculate()
        },
        calculate() {
            if(this.amount > 0) {
                const quantity = this.amount * this.detail?.rate
                const commission = (quantity * parseFloat(this.detail?.commission)) / 100
                const result = (quantity - commission) - parseFloat(this.detail?.addination_commission)
                this.quantity = this.numberFormat((result >= 0 ? result : 9), 0)
            } else {
                this.quantity = 0
            }
        },
        splitter(item, separator = '=') {
            return item.split(separator)
        },
        onsubmit(event) {
            event.preventDefault()
            var formData = new FormData(event.target)
            let formObject = Object.fromEntries(formData.entries())
            let status
            if (parseFloat(formObject.amount) > parseFloat(this.balance)) {
                status = false
                this.notification('Недостаточно баланса на счете.')
            }
            if (this.quantity < parseFloat(this.detail?.min_sum)) {
                status = false
                this.notification('Минимальная сумма ' + this?.detail.min_sum)
            }
            if (this.quantity > parseFloat(this.detail?.max_sum)) {
                status = false
                this.notification('Максимальная сумма ' + this.detail.max_sum)
            }
            if (this.detail?.regex && formObject.requisite) {
                const regex = new RegExp(this.detail.regex, 'g')
                if (!regex.test(formObject.requisite)) {
                    status = false
                    this.notification('Неверный реквизит платежного документа')
                }
            }
            if (status !== false) {
                formData.append('serviceId', this.serviceId)
                this.$store.dispatch('createPayment', formData)
            }
        },
        confirmPayment() {
            const data = {guid: this.confirm.id}
            if(this.twofactor) {
                data['2fa_otp'] = String(this.$refs.code.value)
            }
            this.$store.dispatch('confirmPayment', data)
        }
    }
}
</script>