<template>
    <Header></Header>
    <div class="container min-height padding-top-default">
        <div class="contacts-page z-index">
            <h1 class="title">Контакты</h1>
            <div class="row">
                <div class="col-12 col-md-5 col-lg-5 col-xl-5">
                    <span class="contacts-label mt-0">Наш адрес</span>
                    <b class="contacts-info">Бишкек, ул. Усенбаева 138а, оф. 3</b>
                    <span class="contacts-label">График работы</span>
                    <b class="contacts-info">Пн-Пт 10:00 - 20:00</b>
                    <b class="contacts-info">Сб-Вс 11:00 - 19:00</b>
                    <span class="contacts-label">Телефон</span>
                    <a href="tel:+996501028120" target="_blank" class="contacts-info">+996 (501) 028-120 (Whatsapp)</a>
                    <a href="tel:+996501028120" target="_blank" class="button mt-3">Позвонить</a>
                    <span class="contacts-label">Телеграм</span>
                    <b class="contacts-info">Напишите нашему менеджеру в Telegram <a href="https://t.me/bitokkg" target="_blank">@bitokkg</a></b>
                    <a href="https://t.me/bitokkg" target="_blank" class="button mt-3">Написать</a>
                    <span class="contacts-label">Электронная почта</span>
                    <b class="contacts-info"><a href="mailto:business@bitok.kg" target="_blank">business@bitok.kg</a> - по поводу сотрудничества</b>
                    <b class="contacts-info"><a href="mailto:info@bitok.kg" target="_blank">info@bitok.kg</a> - для получения помощи</b>
                </div>
                <div class="col-12 col-md-7 col-lg-7 col-xl-7 contact-iframe">
                    <iframe src="https://yandex.ru/map-widget/v1/-/CDWqA2OD" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            <div class="py-5"></div>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
    components: {
        Header,
        Footer
    }
}
</script>