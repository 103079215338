<template>
    <Header></Header>
    <div class="container min-height padding-top-default">
        <div class="faq faq-page z-index">
            <div class="row">
                <div class="col-12 col-md-12 col-lg-4">
                    <h1 class="faq-title">FAQ</h1>
                    <p class="faq-subtitle">Найдите ответы, на интересующие вопросы</p>
                    <div class="tab">
                        <template v-for="(section, faqCategory) in FAQList" :key="faqCategory">
                            <button class="tab-button lg" :class="category === faqCategory && 'active'" v-on:click="category = faqCategory">{{ section[0].name }}</button>
                        </template>
                    </div>
                </div>
                <div class="col-12 col-md-12 col-lg-8">
                    <template v-for="(section, faqCategory) in FAQList" :key="faqCategory">
                        <div class="faq-wrap" v-if="category === faqCategory">
                            <template v-for="(faq, index) in section" :key="index++">
                                <div class="faq-box" :class="index == faqIndex && 'active'" v-on:click="open(index)">
                                    <span>{{ faq.question }}</span>
                                    <p v-html="faq.answer"></p>
                                </div>
                            </template>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
    data() {
        return {
            faqIndex: 0,
            category: ''
        }
    },
    components: {
        Header,
        Footer
    },
    created() {
        if(this.$store.getters.getFAQList) {
            this.category = Object.keys(this.$store.getters.getFAQList)[0]
        }
    },
    computed: {
        FAQList() {
            return (this.$store.getters.getFAQList)
            ? this.$store.getters.getFAQList
            : this.$store.dispatch('getFAQList')
        }
    },
    watch: {
        FAQList() {
            this.category = Object.keys(this.$store.getters.getFAQList)[0]
            console.log(this.category)
        }
    },
    methods: {
        open(index) {
            this.faqIndex = this.faqIndex == index ? 0 : index
        }
    }
}
</script>