<template>
    <p class="text info mb-0 mt-4">Здесь вы можете изменить свои персональные данные, чтобы изменить E-mail или номер телефона обратитесь в службу поддержки</p>
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-6">
            <div class="dashboard-personal-wrap">
                <form v-on:submit.prevent="onsubmit" class="form" autocomplete="off" v-if="profile">
                    <label class="profile-photo">
                        <input type="file" class="d-none" @change="avatarPreview">
                        <img v-if="photoPreview" :src="photoPreview">
                    </label>
                    <label class="form-label">Имя</label>
                    <div class="form-group mb-4">
                        <input type="text" name="fisrtname" class="form-input" v-model="firstname" required :disabled="loading">
                    </div>
                    <label class="form-label">Фамилия</label>
                    <div class="form-group mb-4">
                        <input type="text" name="lastname" class="form-input" v-model="lastname" required :disabled="loading">
                    </div>
                    <label class="form-label">E-mail</label>
                    <div class="form-group mb-4">
                        <input type="text" class="form-input" :value="profile.email" disabled>
                    </div>
                    <template v-if="profile.phone">
                        <label class="form-label">Номер телефона</label>
                        <div class="form-group mb-4">
                            <input type="text" class="form-input" :value="profile.phone" disabled>
                        </div>
                    </template>
                    <button class="button block lg mt-4" type="submit" :disabled="loading">Сохранить</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            photo: '',
            photoPreview: ''
        }
    },
    created() {
        if(this.profile) {
            this.firstname = this.profile.firstname
            this.lastname = this.profile.lastname
            this.photoPreview = this.profile.avatar
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        profile() {
            return this.$store.getters.getProfile
        }
    },
    watch: {
        profile() {
            if(this.profile) {
                this.firstname = this.profile.firstname
                this.lastname = this.profile.lastname
                this.photoPreview = this.profile.avatar
            }
        }
    },
    methods: {
        avatarPreview(e) {
            this.photo = e.target.files[0]
            this.photoPreview = URL.createObjectURL(this.photo)
        },
        onsubmit() {
            if(!this.firstname || this.firstname === '') {
                this.$store.dispatch('notification', {message: 'Введите имя'})
            } else {
                if(!this.lastname || this.lastname === '') {
                    this.$store.dispatch('notification', {message: 'Введите фамилию'})
                } else {
                    const data = {
                        firstname: this.firstname,
                        lastname: this.lastname,
                        photo: this.photo
                    }
                    this.$store.dispatch('updateProfileInfo', data)
                }
            }
        }
    }
}
</script>