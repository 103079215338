<template>
    <Header></Header>
    <div class="container min-height padding-top-default">
        <div class="rules-page z-index">
            <div class="row pb-4">
                <h1 class="text-center">
                    ПУБЛИЧНАЯ ОФЕРТА<br>
                    НА ЗАКЛЮЧЕНИЕДОГОВОРА ПРИСОЕДИНЕНИЯ<br>
                    ОБ ИСПОЛЬЗОВАНИИ ЭЛЕКТРОННОЙ СИСТЕМЫ «Bitok.kg» ОсОО «БИТОК ПЛЮС»
                </h1>
                <br>
                <p>1. ОБЩИЕ ПОЛОЖЕНИЯ</p>
                <p>1.1. Настоящий Договор является публичным договором (публичной офертой на основании части 2 статьи
                    398
                    Гражданского Кодекса Кыргызской Республики) Общества с ограниченной ответственности «Биток плюс»,
                    именуемого в дальнейшем Исполнитель, в лице директора Казаковой М.Э., действующей на основании
                    Устава,
                    утвержденного Решением о создании Общества от 1 ноября 2022 года, с любым юридическим или физическим
                    лицом, выразившим готовность воспользоваться услугами Исполнителя, принявшим условия и
                    присоединившимся
                    к настоящему Договору (оферте), именуемым в дальнейшем Заказчик, на оказание услуг, перечень которых
                    содержится в прайс-листе (далее - Услуги), опубликованном на официальном интернет-канале Исполнителя
                    в
                    сети Интернет по адресу: Bitok.kg.
                </p>
                <p>1.2. Публичная оферта, выраженная в настоящем Договоре, вступает в силу с момента размещения в сети
                    Интернет по адресу: Bitok.kg и действует до момента отзыва оферты Исполнителем.
                </p>
                <p>1.3. Акцептом (безусловным принятием) условий настоящей публичной оферты (моментом заключения
                    Договора)
                    в соответствии со статьей 399 Гражданского кодекса Кыргызской Республики считается факт оплаты
                    Заказчиком Услуг, предоставляемых Исполнителем в соответствии с условиями настоящего Договора.
                </p>
                <p>1.4. Осуществляя акцепт настоящей публичной оферты в порядке, определенном п. 1.3. настоящего
                    Договора,
                    Заказчик соглашается со всеми условиями Договора в том виде, в каком они изложены в тексте
                    настоящего
                    Договора, опубликованного по адресу: Bitok.kg, являющегося неотъемлемой частью настоящего
                    Договора, и в соответствии с Гражданским кодексом Кыргызской Республики рассматривается как лицо,
                    вступившее с Исполнителем в договорные отношения на основании публичной оферты на заключение
                    настоящего
                    Договора путем присоединения. При этом в соответствии со статьями 395 и 402 Гражданского кодекса
                    Кыргызской Республики настоящий Договор признается заключенным Сторонами в письменной форме.
                </p>
                <p>1.5. Исполнитель вправе в любое время по своему усмотрению изменить условия публичной оферты
                    (настоящего
                    Договора) или отозвать ее. В случае изменения Исполнителем условий настоящего Договора, изменения
                    вступают в силу с момента размещения измененных условий в сети Интернет по адресу: Bitok.kg,
                    если иной срок не указан Исполнителем при таком размещении.
                </p>
                <p>1.6. Термины и определения:
                    Виртуальный актив - совокупность данных в электронно-цифровой форме, имеющая стоимость, являющаяся
                    цифровым выражением ценности и/или средством удостоверения имущественных и/или неимущественных прав,
                    которая создается, хранится и обращается с использованием технологии распределенных реестров или
                    аналогичной технологии и не является денежной единицей (валютой), средством платежа и ценной
                    бумагой.
                    Денежные средства – средства в сомах и/или иной иностранной валюте, находящиеся в кассе, на
                    расчетном,
                    валютном и других счетах в банках и/или других учреждениях (включая лицевые счета поставщиков
                    товаров и
                    услуг), а также для целей настоящего Договора под «денежными средствами», «переводом денежных
                    средств»
                    могут в зависимости от контекста и особенностей оказания соответствующих услуг также пониматься
                    «электронные деньги» и соответствующие операции с «электронными деньгами».
                    Заявка – безотзывное поручение, направленное со стороны Пользователя в адрес Оператора посредством
                    Системы в установленной Системой форме о предоставлении или оказании необходимых услуг по покупке и
                    продаже (обмену) виртуальных активов и (или) обмена между виртуальными активами.
                    Верификация - процедура установления идентификационных данных о Пользователе и бенефициарном
                    владельце
                    Пользователя.
                    Контрагент – третье лицо с которым Оператором установлены договорные отношения, участие которого
                    необходимо для проведения Обмена.
                    Меры ПФТД/ЛПД – мероприятия, проводимые Оператором в целях соблюдения законодательства по
                    противодействию финансирования террористической деятельности и легализации (отмыванию) преступных
                    доходов.
                    Обмен – совокупность операций, производимых Оператором в целях исполнения Заявки Пользователя
                    связанная
                    с перемещением денежных средств/виртуальных активов между Оператором и Пользователем с
                    использованием
                    различных инструментов и на основании установленных Оператором договорных взаимоотношений с
                    контрагентами, по указанным Пользователем реквизитам.
                    Оператор – ОсОО «Биток плюс», предоставляющее услуги виртуальных активов и выполняющее функции
                    координации и обеспечения надлежащей деятельности Системы в целом.
                    Период исполнения Заявки – период времени который начинается с момента получения Заявки Оператором и
                    заканчивается моментом списания Контрагентом средств со счетов Оператора в целях перечисления
                    денежных
                    средств/виртуальных активов по указанным в Заявке реквизитам.
                    Пользователь – физическое лицо, достигшее 18 (восемнадцати) лет, правоспособное и дееспособное, в
                    том
                    числе физическое лицо, осуществляющее предпринимательскую деятельность, или юридическое лицо,
                    заключившее с Оператором Договор, а также прошедшее процедуру Идентификации и имеющее право
                    использовать/совершать финансовые транзакции (платежи).
                    Рабочий день – календарный день, определенный рабочим днем согласно производственному календарю
                    Кыргызской Республики (время –
                    г. Бишкек).
                    Счет – под счетами Оператора и/или Пользователя в рамках настоящего договора могут пониматься:
                    банковский счет, карточный счет, счет электронного кошелька, счет инструмента хранения виртуальных
                    активов, лицевой счет различных поставщиков товаров и услуг в том числе различных электронных
                    площадок.
                    Система – это программное обеспечение Оператора имеющее пользовательский интерфейс как в виде
                    мобильного
                    приложения, так и веб-интерфейса, предназначенное для Пользователей Системы, которое позволяет
                    Пользователям использовать Систему посредством интернет соединения в целях получения услуг Обмена
                    предоставляемых Оператором и использовать другие сервисы, не противоречащие законодательству
                    Кыргызской
                    Республики.
                    Тариф - определенная сумма денежных средств, дополнительно взимаемая в качестве компенсации расходов
                    и/или вознаграждения Оператора Системы, связанных с исполнением Оператором Системы своих
                    обязательств по
                    настоящему Договору.
                    Услуги виртуальных активов – лицензионная деятельность, осуществляемая Оператором в качестве
                    поставщика
                    услуг виртуальных активов, предоставляющая право Оператору на оказание услуг покупки и продажи
                    (обмена)
                    виртуальных активов и (или) обмена между виртуальными активами от своего имени в целях исполнения
                    Платежа.
                    Устройство Пользователя — находящееся в законном владении Пользователя сертифицированное
                    пользовательское (оконечное) оборудование, позволяющее стать пользоваться услугами Обмена,
                    обеспечивающее Пользователю доступ к услугам Оператора посредством подключения данного оконечного
                    оборудования к сети связи Оператора, в том числе персональный компьютер.
                    Участник Системы – Оператор Системы, Пользователь, Поставщик и иные лица, имеющие право доступа к
                    Системе.
                </p>
                <p>2. ПРЕДМЕТ ДОГОВОРА</p>
                <p>2.1. По настоящему Договору Исполнитель обязуется оказывать Заказчику услуги, связанные с
                    осуществлением
                    деятельности обмена виртуальных активов, а Заказчик обязуется принять и оплатить их в соответствии с
                    условиями настоящего Договора.
                </p>
                <p>2.2. С момента оплаты Услуг (проведения виртуальной операции) Заказчик признается заключившим
                    настоящий
                    Договор.
                </p>
                <p>3. УСЛОВИЯ ИСПОЛЬЗОВАНИЯ СИСТЕМЫ</p>
                <p>3.1. Для Авторизации Пользователя при входе в Мобильное приложение или Веб-Сайт Системы используется
                    Код
                    Доступа или отпечаток пальца Пользователя. Обращение Пользователя к Системе, имевшее место с
                    использованием ассоциированных с Пользователем средств авторизации (включая, но не ограничиваясь:
                    Код
                    доступа и т.д.) признается безусловным фактом, подтверждающим использование Системы Пользователем,
                    при
                    этом действия, осуществляемые с использованием ассоциированных с Пользователем средств авторизации,
                    признаются действиями, совершенными лично Пользователем.
                </p>
                <p>3.2. Для осуществления Обмена Пользователь передает Оператору Заявку. Путем оформления Заявки
                    Пользователь поручает, а Оператор от своего имени и за счет Пользователя, совершает действия по
                    Обмену.
                    Создавая Заявку, Пользователь дает безусловное и безотзывное поручение(-я) Пользователя в сторону
                    Оператора для выполнения Обмена, а именно:
                </p>
                <p>3.2.1. поручение Пользователя Оператору произвести Обмен, используя электронные деньги, банковскую
                    платёжную карту, денежные средства, виртуальные активы Пользователя, принятые в качестве
                    авансов/предоплаты, которыми Пользователь вправе распоряжаться на законных основаниях;
                </p>
                <p>3.2.2. создание Заявки выполняется в электронном и/или бумажном виде путем заполнения полей
                    реквизитов в
                    Мобильном Приложении, на Веб-Сайте Системы или в иной форме согласованной Оператором. Нажатие кнопки
                    акцепта операции Обмена или осуществление иных действий, прямо указанных Оператором, означает
                    Согласие
                    Пользователя на выполнение Распоряжения на Обмен и всех условий такого Распоряжения, Платежа, Тарифа
                    и
                    способа Обмена.
                </p>
                <p>3.3. Обмен может проводиться по иным основаниям, указанным в настоящем Договоре и соответствующих
                    нормативных документах Оператора, размещенных на Веб-Сайте Системы и/или в Мобильном приложении или
                    предоставленных Пользователю и не противоречащих законодательству Кыргызской Республики.
                </p>
                <p>3.4. Заявка, составленная Пользователем посредством ввода данных в Мобильном приложении или на
                    Веб-Сайте
                    Системы и нажатии кнопки акцепта или осуществлением иных действий, прямо указанных Оператором,
                    признается Сторонами надлежащим и достаточным способом подтверждения намерения Пользователя
                    совершить
                    Обмен и подлинности направленной Заявки. В некоторых случаях Оператор может потребовать ввода
                    дополнительного пароля, который высылается в виде SMS-сообщения или сообщения Мобильного приложения.
                </p>
                <p>3.5. Код Доступа (или отпечаток пальца Пользователя) в отдельности и/или в сочетании с паролем и/или
                    нажатием кнопки акцепта признается Сторонами аналогом собственноручной подписи. Применение
                    Пользователем
                    Кода Доступа порождает юридические последствия, аналогичные использованию собственноручных подписей.
                    Все
                    действия Пользователя, совершенные им или третьими лицами после ввода Кода Доступа, заданным самим
                    Пользователем в процессе регистрации, считаются совершенными самим Пользователем и приравниваются к
                    поручениям, предоставленным в письменной форме и собственноручно подписанным Пользователем.
                </p>
                <p>3.6. В случае предъявления Пользователем претензий Оператору относительно использования и/или
                    подлинности Кода Доступа/пароля, факта внесения изменений в Распоряжение на оплату или в иной
                    документ
                    после его отправки Оператору, факта получения Оператором Заявки, Пользователь обязуется приложить к
                    претензии соответствующие доказательства наличия вышеуказанных фактов. В случае достаточности
                    доказательств и наличия соответствующей возможности, Оператор, используя Систему, вправе производить
                    проверку наличия указанных фактов без принятия обязательств по возврату денежных средств или
                    виртуального актива.
                </p>
                <p>3.7. Заявка Пользователя не исполняется Оператором Системы в следующих случаях:</p>
                <p>3.7.1. Пользователем не соблюдаются условия настоящего Договора;</p>
                <p>3.7.2. Имеет место техническая невозможность оказания запрошенной услуги Обмена у Оператора и/или
                    оператора связи;
                </p>
                <p>3.7.3. Невозможность оказания услуг Обмена, которые желает оплатить Пользователь;</p>
                <p>3.7.4. При возникновении у Оператора оснований полагать, что существует риск направления
                    несанкционированной Заявки;
                </p>
                <p>3.7.5. Временное отсутствие доступа к интернету у Пользователя или отсутствие связи с Оператором
                    и/или
                    оператором связи. При этом при наличии соответствующей возможности Заявки могут быть исполнены после
                    устранения проблем со связью или отсутствием интернета без необходимости повторной отправки Заявки.
                </p>
                <p>3.7.6. В случаях, когда у Оператора имеются данные или информация о том, что Пользователь проводил
                    Платежи с привязанной банковской карты, держателем которой Пользователь не является или в случаях,
                    когда
                    Пользователь отдает Оператору Заявку с использованием денежных средств или виртуальных активов,
                    собственником которых он не является или указанные распоряжения не санкционированы собственником
                    денежных средств или виртуальных активов;
                </p>
                <p>3.7.7. В случаях, предусмотренных законодательством по противодействию финансирования
                    террористической
                    деятельности и легализации (отмыванию) преступных доходов.
                </p>
                <p>3.8. В случае успешности выполнения Заявки Оператор информирует Пользователя о результатах
                    осуществления
                    Обмена сообщением Системы, а также формированием в Системе электронного документа, содержащим все
                    реквизиты операции Обмена.
                </p>
                <p>3.9. Оператор считается исполнившим обязательства по Заявке Пользователя в полном объеме с момента
                    списания Контрагентом средств со счетов Оператора в целях исполнения операции Обмена.
                    Возврат денежных средств и/или виртуального актива осуществляется по инициативе Оператора, в связи с
                    отменой ранее произведенного Обмена, по причине возникшего технического сбоя.
                </p>
                <p>3.10. Ограничения (особенности) использования Системы:</p>
                <p>3.10.1. Перечисленные в настоящем разделе Договора ограничения (особенности) использования Системы
                    не
                    являются обстоятельствами ненадлежащего оказания услуги использования Системы и не являются
                    основаниями
                    для отказа Пользователя от оплаты Тарифа и/или заявления требований о возмещении убытков;
                </p>
                <p>3.10.2. Перечень предлагаемых услуг и лимиты Обмена могут зависеть от вида прохождения Пользователем
                    процедуры регистрации и идентификации;
                </p>
                <p>3.10.3. Оператор вправе ввести персональные ограничения на доступ к перечню Услуг на основании
                    подозрительных действий Пользователя, вплоть до полной блокировки доступа, временных или постоянных
                    подозрений в нарушении Пользователем условий Договора;
                </p>
                <p>3.10.4. Оператор вправе по своему усмотрению уменьшать максимальную сумму единовременного Обмена, а
                    также общую сумму Обмена в сутки и/или в месяц, в пользу отдельных Пользователей, и/или по отдельным
                    видам Услуг Поставщиков;
                </p>
                <p>3.10.5. Оператор вправе в безакцептном одностороннем порядке приостановить проведение Обмена и
                    направить
                    денежные средства и/или виртуальный актив пользователя на погашение задолженностей или обязательств
                    Пользователя.
                </p>
                <p>4. ПРАВА И ОБЯЗАННОСТИ СТОРОН</p>
                <p>4.1. Обязанности Пользователя:</p>
                <p>4.1.1. Пользователь обязуется сообщать при регистрации и идентификации сведения, соответствующие
                    действительности и нести дальнейшую ответственность за достоверность предоставленных сведений;
                </p>
                <p>4.1.2. Пользователю запрещается осуществлять с помощью Системы предпринимательскую деятельность без
                    заключения с Оператором отдельного соответствующего договора;
                </p>
                <p>4.1.3. Пользователь обязан пресекать попытки формирования Заявок с использованием Кодов Доступа
                    Пользователя третьими лицами и не передавать Коды Доступы, а также Устройство Пользователя с
                    установленной Системой/Мобильным приложением третьим лицам;
                </p>
                <p>4.1.4. При использовании Системы Пользователю запрещается вносить какие-либо изменения в программное
                    обеспечение Системы и/или любую его часть своими силами или с привлечением третьих лиц;
                </p>
                <p>4.1.5. Пользователь обязан своевременно уведомлять Оператора об изменении своих персональных данных
                    (а
                    именно: фамилии, имени, отчества; адреса места жительства (места пребывания), реквизитов документа,
                    удостоверяющего личность; реквизитов Пользователя-юридического лица);
                </p>
                <p>4.1.6. Пользователь обязуется не использовать Систему в противозаконных целях или для приобретения
                    товаров и оплаты услуг, запрещённых законодательством Кыргызской Республики;
                </p>
                <p>4.1.7. Пользователь обязан незамедлительно сообщить о случае утраты контроля над Устройством
                    Пользователя Оператору;
                </p>
                <p>4.1.8. Пользователь обязан гарантировать, что продаваемый Пользователем виртуальный актив был
                    получен
                    законным способом, в споре или под арестом не состоит, не является предметом залога, не обременен
                    другими правами третьих лиц и не нарушает прав третьих лиц;
                </p>
                <p>4.1.9. Пользователь обязан по запросу Оператора до совершения сделок с виртуальными активами
                    подтвердить
                    достаточный уровень знаний (компетентности) для совершения таких сделок путем посредством
                    представления
                    Пользователям вопросника (теста) для заполнения (решения);
                </p>
                <p>4.1.10. Пользователь обязан незамедлительно уведомить Оператора об обнаруженных им
                    нерегламентированных
                    возможностях системы, уязвимостях или проблемах, связанных с информационной безопасностью;
                </p>
                <p>4.1.11. В случае если Пользователь совершил действия с нерегламентированными возможностями Системы,
                    уязвимостями или проблемами, связанными с информационной безопасностью, повлекшие ущерб Оператора
                    или
                    третьих лиц, он обязуется компенсировать возникший прямой и косвенный ущерб лицу, которому он был
                    нанесен.
                </p>
                <p>4.2. Оператор в рамках Договора оставляет за собой право:</p>
                <p>4.2.1. До совершения с Пользователями сделок по продаже им виртуальных активов за деньги убедиться в
                    том, что они обладают достаточным уровнем знаний (компетентности) для совершения таких сделок путем
                    посредством представления Пользователям вопросника (теста) для заполнения (решения). В случае если
                    результаты данной оценки дают основания Оператору полагать, что Пользователь не обладает достаточным
                    уровнем знаний (компетентности) для совершения сделок с виртуальными активами Оператор отказывает
                    пользователю в совершении сделок с виртуальными активами;
                </p>
                <p>4.2.2. В любой момент времени, без уведомления Пользователей, производить модификацию любого
                    программного обеспечения Системы, Веб-Сайта Системы и Мобильного приложения;
                </p>
                <p>4.2.3. Приостанавливать работу программных и/или аппаратных средств, обеспечивающих функционирование
                    Системы, при обнаружении существенных неисправностей, ошибок и сбоев, а также в целях проведения
                    профилактических работ и предотвращения случаев несанкционированного доступа к Системе;
                </p>
                <p>4.2.4. В случае, если Оператором или соответствующими государственными органами установлен факт
                    сомнительных финансовых операций, проведенных Пользователем с помощью Системы, в безакцептном
                    одностороннем порядке принять необходимые меры, в том числе заблокировать возможность проведения
                    операций Обмена с помощью Системы, отменить любые сомнительные операции по нему, запретить
                    проведение
                    некоторых операций, в безакцептном порядке списать любые Платежи и вернуть первоначальному
                    собственнику
                    денежные средства;
                </p>
                <p>4.2.5. Ограничить без предварительного уведомления в одностороннем порядке доступ Пользователя к
                    использованию Системы, если использование Системы Пользователем, исключительно на усмотрение
                    Оператора,
                    может создать или создает угрозу финансовой безопасности самому Пользователю, другим Пользователям,
                    Оператору, Контрагентам и третьим лицам, если возникает техническая необходимость ограничения
                    доступа
                    и/или нештатная ситуация Системе, если возникают риски ликвидности, кредитные/системные/правовые/
                    юридические и прочие риски;
                </p>
                <p>4.2.6. Ограничить без предварительного уведомления в одностороннем порядке доступ Пользователя к
                    использованию Системы, если Пользователь нарушает положения законодательства Кыргызской Республики,
                    условия Договора, незаконно использует оборудование связи, пользуется услугами связи незаконным
                    образом,
                    использует несертифицированное Устройство Пользователя и/или программное обеспечение, нарушает
                    правила
                    эксплуатации средств связи, уведомив об этом Пользователя SMS-сообщением и/или сообщением на
                    электронную
                    почту Пользователя и/или сообщением Системы и/или любым другим способом;
                </p>
                <p>4.2.7. Приостановить деятельность Системы, предварительно уведомив об этом Пользователя
                    SMS-сообщением
                    и/или сообщением на электронную почту Пользователя и/или сообщением Системы и/или любым другим
                    способом;
                </p>
                <p>4.2.8. Оператор вправе приостановить операцию и удержать средства Пользователя, в целях
                    предотвращения
                    мошеннических и других действий, которые могут нанести финансовые и репутационные потери для Сервиса
                    или
                    Пользователя;
                </p>
                <p>4.2.9. Оператор вправе отказать Пользователю в формировании заявки без пояснения причин отказа
                </p>
                <p>4.2.10.
                    Производить в отношении участников все необходимые меры ПФТД/ЛПД в случаях определенных
                    законодательством Кыргызской Республики.
                </p>
                <p>5. СТОИМОСТЬ И ПОРЯДОК ОПЛАТЫ</p>
                <p>5.1. Расчеты с Пользователем осуществляются по курсу, определяемому Оператором, в национальной
                    валюте
                    Кыргызской Республики – сом, международной валюте или в виртуальном активе в зависимости от типа
                    операции.
                </p>
                <p>5.2. Оператор вправе взимать с Пользователя Тариф. Размеры тарифов, основания, условия и момент их
                    оплаты Пользователем указаны в Мобильном приложении и на Веб-Сайте Системы.
                </p>
                <p>5.3. Если Пользователь не согласен с Тарифом и/или курсом Обмена – он должен отказаться от
                    формирования
                    Заявки, при этом Пользователь не вправе требовать возмещения ему и/или третьим лицам каких-либо
                    убытков
                    с связи с невозможностью использования Системы по данному основанию. Пользователь принимает на себя
                    ответственность и все последствия использования Системы совершенного со своего Устройства и в
                    Мобильном
                    приложении. В случае совершения ошибочного Платежа Пользователем услуга считается надлежаще
                    оказанной, и
                    сумма уплаченного тарифа возврату не подлежит.
                </p>
                <p>5.4. Оператор вправе в одностороннем порядке полностью или частично изменять (увеличивать,
                    уменьшать),
                    устанавливать новые, отменять существующие курсы Обмена и/или Тарифы, предпринимая возможные меры по
                    уведомлению Пользователей.
                </p>
                <p>6. ОТВЕТСТВЕННОСТЬ СТОРОН</p>
                <p>6.1. За невыполнение или ненадлежащее выполнение обязательств по настоящему Договору Стороны несут
                    ответственность согласно условиям настоящего Договора и действующему законодательству Кыргызской
                    Республики.
                </p>
                <p>6.2. Пользователь несет полную и безоговорочную ответственность за правильность оформления Заявки,
                    указания реквизитов, необходимых для осуществления Обмена (реквизиты Пользователя, сумма Обмена и
                    прочее), и не вправе выдвигать в требовательной форме заявление Оператору по корректировке/возврату
                    Обмена по причинам ошибочных действий Пользователя.
                </p>
                <p>6.3. Пользователь не вправе предъявлять Оператору требование о возврате Платежей, осуществленных
                    через
                    Устройство, контроль доступа к которым был утрачен по причинам, не зависящим от Оператора, в том
                    числе с
                    использованием утерянной SIM-карты и/или передачи Кода Доступа и/или Устройства Пользователя, до
                    момента
                    получения Оператором соответствующего заявления.
                </p>
                <p>6.4. Оператор не возмещает Пользователю комиссии, тарифы платежных систем при Возврате.
                </p>
                <p>6.5. В случае возврата виртуальных активов/денежных средств Пользователю Оператор использует в
                    расчетах
                    суммы возврата курсы, действующие на момент возврата, во избежание получения убытков от курсовой
                    разницы.
                </p>
                <p>6.6. Пользователь несет риски и ответственность за любые действия третьих лиц, совершенных от имени
                    Пользователя посредством Устройства Пользователя, а также с помощью установленных на Устройстве
                    Пользователя приложений и программ или с помощью его
                    идентификационных/аутентификационных/авторизационных данных в Мобильном Приложении или на Веб-Сайте
                    Системы, в том числе в случае утраты доступа по причинам, не зависящим от Оператора вплоть до
                    момента
                    получения Оператором от Пользователя соответствующего письменного заявления.
                </p>
                <p>6.7. Пользователь несет все риски и ответственность, возникшие в результате проведения Обмена,
                    несанкционированного собственниками денежных средств и виртуальных активов.
                </p>
                <p>6.8. Оператор не несет ответственности перед Пользователем за задержки и перебои в работе
                    технических
                    платформ и транспортных сетей или сетей связи, в возникновении которых нет вины Оператора.
                </p>
                <p>6.9. Оператор не несет ответственности за ущерб любого рода, понесенный Пользователем из-за
                    разглашения
                    Пользователем своей авторизационной информации, содержимого SMS с аутентификационными данными, Кода
                    Доступа и персональных данных или ставшей доступной третьим лицам из-за халатных действий
                    Пользователя.
                </p>
                <p>6.10. Оператор не отвечает за убытки Пользователя, возникшие в результате неправомерных действий
                    третьих
                    лиц
                </p>
                <p>6.11. Оператор не несет ответственности за непроведение Обмена в случае несоблюдения Пользователем
                    требований и условий, установленных настоящим Договором.
                </p>
                <p>7. ПОРЯДОК ЗАКЛЮЧЕНИЯ И СРОК ДЕЙСТВИЯ ДОГОВОРА</p>
                <p>7.1. Настоящий Договор заключается между Оператором и Пользователем в форме договора присоединения
                    (ст.
                    387 Гражданского кодекса Кыргызской Республики).
                </p>
                <p>7.2. Пользователь производит акцепт Договора после ознакомления с его условиями на Веб-Сайте Системы
                    или
                    в Мобильном Приложении, установленном на Устройстве Пользователя, путем нажатия кнопки акцепта или
                    осуществления иных действий, прямо указанных Оператором.
                </p>
                <p>7.3. Акцепт условий Договора означает полное и безоговорочное принятие Пользователем всех условий
                    Договора без каких-либо изъятий и/или ограничений и равносилен заключению двухстороннего письменного
                    Договора об использовании Системы.
                </p>
                <p>7.4. Договор действует в течение неопределенного срока или до момента расторжения на основаниях,
                    определенных условиями Договора и/или нормами действующего законодательства Кыргызской Республики.
                </p>
                <p>8. ИЗМЕНЕНИЕ И РАСТОРЖЕНИЕ ДОГОВОРА</p>
                <p>8.1. Оператор вправе в одностороннем порядке вносить изменения в Договор путем публикации изменений
                    на
                    публичных ресурсах. Изменения вступают в силу с момента опубликования, если иной срок вступления
                    изменений в силу не определен дополнительно при их публикации. В случае несогласия Пользователя с
                    изменениями условий Договора, он вправе отказаться от исполнения Договора.
                </p>
                <p>8.2. Использование Системы Пользователем после внесения изменений в Договор однозначно понимается
                    Сторонами как согласие со всеми внесенными изменениями.
                </p>
                <p>8.3. Оператор имеет право в одностороннем порядке без обращения в суд отказаться от исполнения
                    настоящего Договора, с предварительным уведомлением Пользователя не менее, чем за 10 (десять)
                    календарных дней до предполагаемой даты отказа от исполнения Договора. Оператор Системы вправе
                    направить
                    подобное уведомление средствами Системы. Договор считается прекращенным по истечении 10 (десяти)
                    календарных дней после отправления указанного уведомления.
                </p>
                <p>8.4. Пользователь имеет право в любой момент отказаться от исполнения настоящего Договора путем
                    письменного уведомления Оператора Системы не менее, чем за 10 (десять) календарных дней до
                    предполагаемой даты прекращения Договора, за исключением случаев, когда у Пользователя имеется
                    непогашенная задолженность перед Оператором или возможность проведения Обмена является
                    заблокированной
                    по основаниям, предусмотренным в настоящем Договоре.
                </p>
                <p>8.5. Договор может быть прекращен по иным основаниям, предусмотренным настоящим Договором и/или
                    действующим законодательством Кыргызской Республики.
                </p>
                <p>9. РАБОТА С ПЕРСОНАЛЬНЫМИ ДАННЫМИ</p>
                <p>9.1. Пользователь дает свое согласие на сбор, обработку, хранение его персональных данных,
                    предоставленных при получении Услуг и передачу их третьим лицам, в том числе на трансграничную
                    передачу,
                    в соответствии с Законом Кыргызской Республики «Об информации персонального характера»:
                </p>
                <p>9.1.1. С целью идентификации и верификации личности в соответствии с требованиями, определенными
                    законодательством Кыргызской Республики и условиями Договора, в адрес субъектов, участие которых
                    необходимо для предоставления Услуг;
                </p>
                <p>9.1.2. При использовании государственных услуг, предоставляемых различными государственными
                    организациями, включая государственный уполномоченный орган в сфере защиты персональных данных, их
                    контрагентами и подведомственными организациями, в адрес данных организаций;
                </p>
                <p>9.1.3. В случае запроса государственных органов, в адрес государственных органов;</p>
                <p>9.1.4. В случае запроса Службы регулирования и надзора за финансовым рынком при Министерстве
                    экономики и
                    коммерции Кыргызской Республики, в адрес Службы регулирования и надзора за финансовым рынком при
                    Министерстве экономики и коммерции Кыргызской Республики;
                </p>
                <p>9.1.5. С целью локальной и трансграничной передачи персональных данных Контрагентам Оператора при
                    использовании услуг, предоставляемых при осуществлении Обмена виртуальных активов.
                </p>
                <p>9.1.6. С целью получения рассылки материалов информационного/рекламного на указанный в заявлении
                    номер
                    телефона.
                </p>
                <p>9.1.7. С целью проведения маркетинговых, рекламных кампаний/акций, анализа персональных предпочтений
                    и
                    не только, мониторинга поведения, формирования на базе обезличенных персональных данных отчетов,
                    статистического анализа и разного рода исследований, а также для осуществления получателем
                    персональных
                    данных, партнерами обратной связи, включая направление уведомлений, запросов, в том числе в целях
                    продвижения товаров, работ, услуг получателя персональных данных и партнеров на рынке с
                    использованием,
                    включая, но не ограничиваясь, sms-сервисов, электронной почты, почтовой и электрической связи,
                    исключительно по усмотрению получателя персональных данных, партнера.
                </p>
                <p>9.1.8. С целью использования в дальнейшем в документах простой электронной подписи для подтверждения
                    моих действий.
                </p>
                <p>9.1.9. Иным лицам для целей, не противоречащих законодательству Кыргызской Республики в сфере
                    правового
                    регулирования работы с персональными данными.
                </p>
                <p>9.2. Конфиденциальность. Оператор Системы обязуется соблюдать конфиденциальность в отношении
                    информации
                    о Пользователе, ставшей известной Оператору в связи с использованием Системы Пользователем, за
                    исключением случаев, когда такая информация:
                </p>
                <p>9.2.1. Является общедоступной;</p>
                <p>9.2.2. Раскрыта по требованию или с разрешения Пользователя;</p>
                <p>9.2.3. Подлежит предоставлению Контрагентам в объеме, необходимом для исполнения Договора;</p>
                <p>9.2.4. Требует раскрытия по основаниям, предусмотренным законодательством Кыргызской Республики, или
                    при
                    поступлении соответствующих запросов кредитных организаций, суда или уполномоченных государственных
                    органов;
                </p>
                <p>9.2.8. В иных случаях, предусмотренных Договором и законодательством Кыргызской Республики.</p>
                <p>10. ФОРС-МАЖОР</p>
                <p>10.1. При возникновении нештатных ситуаций или системных рисков, которые выходят за рамки правил и
                    технологии работы системы/платежной инфраструктуры Оператора и Пользователя и требует для их
                    разрешения
                    специально организованной деятельности персонала Сторон, Стороны обязуются уведомлять друг друга о
                    наступлении перерывов в проведении платежей в течение 24 (двадцати четырех) часов с момента их
                    обнаружения.
                </p>
                <p>10.2. По завершению решения нештатной ситуации Стороны обязуются уведомлять друг друга о закрытии
                    возникших проблем в части взаимодействия Сторон в течение 24 (двадцати четырех) часов с момента ее
                    закрытия.
                </p>
                <p>10.3. Все обязательства по взаиморасчетам должны быть исполнены Сторонами независимо от факта
                    возникновения нештатной ситуации.
                </p>
                <p>10.4. Сторона освобождается от ответственности за частичное или полное неисполнение обязательств по
                    настоящему Договору, если это неисполнение явилось следствием обстоятельств непреодолимой силы,
                    возникших после заключения Договора, в результате обстоятельств чрезвычайного характера, которые
                    Сторона
                    не могла ни предвидеть, ни предотвратить разумными мерами. К таким обстоятельствам относятся:
                    телекоммуникационные сбои всеобщего характера, наводнение, пожар, землетрясение и иные явления
                    природы,
                    эпидемии, пандемии, локдауны, введение чрезвычайного положения, а также война, военные действия,
                    военные
                    перевороты, террористические акты, предписание, приказ или иное административное вмешательство со
                    стороны государственных органов, или какие-либо другие постановления, административных или
                    правительственных ограничений, а также другие события, за которые ни одна из Сторон не отвечает.
                </p>
                <p>10.5. При наступлении указанных в п.10.4 обстоятельств, Сторона, исполнению обязательств которой они
                    препятствуют, должна не позднее 3 (трех) рабочих дней, если иной срок не обусловлен
                    документооборотом
                    соответствующего компетентного органа, известить о них в письменном виде другую Сторону. Извещение
                    должно содержать данные о характере обстоятельств, что должно быть подтверждено компетентной
                    государственной или иной организацией, а также, по возможности, оценку их влияния на возможность
                    исполнения Стороной обязательств по Договору и срок исполнения обязательств. Справка компетентных
                    государственных органов, а также иные доказательства наступления форс-мажорных обстоятельств не
                    прилагаются в тех случаях, когда возникновение и действие форс-мажорных обстоятельств очевидно или
                    общеизвестно.
                </p>
                <p>10.6. В случае если обстоятельства, указанные в п.10.4, продлятся более 60 (шестидесяти) календарных
                    дней, Стороны имеют право расторгнуть Договор в одностороннем внесудебном порядке, при этом Стороны
                    должны провести взаиморасчеты по возникшим при исполнении Договора финансовым обязательствам.
                </p>
                <p>11. ПОРЯДОК РАССМОТРЕНИЯ ПРЕТЕНЗИЙ И РАЗРЕШЕНИЯ СПОРОВ</p>
                <p>11.1. При неисполнении или ненадлежащем исполнении Оператором Системы Услуг Пользователь до
                    обращения в
                    суд обязан предъявить Оператору претензию в письменном виде в порядке предварительного досудебного
                    разрешения спора.
                </p>
                <p>11.2. Претензии предъявляются в течение 3-х месяцев со дня оказания услуг или отказа в их оказании.
                </p>
                <p>11.3. Претензия рассматривается Оператором Системы в срок, не превышающий 60 (шестьдесят)
                    календарных
                    дней с даты регистрации претензии.
                </p>
                <p>11.4. При отклонении претензии полностью или частично либо неполучения ответа в установленные для
                    рассмотрения претензии сроки Пользователь имеет право предъявить иск в суд. Территориальная
                    подсудность
                    всех споров между Оператором Системы и Пользователем определяется местом нахождения Оператора
                    Системы в
                    соответствии со статьей 34 Гражданского процессуального кодекса Кыргызской Республики. Пользователь
                    также соглашается, что языком судебного разбирательства будет официальный (русский) язык.
                </p>
                <p>12. ЗАКЛЮЧИТЕЛЬНОЕ ПОЛОЖЕНИЕ</p>
                <p>12.1. Настоящий Договор (публичная оферта) представляет собой полную договоренность между
                    Исполнителем и
                    Заказчиком. Исполнитель и Заказчик не принимают на себя никаких других условий и обязательств в
                    рамках
                    исполнения настоящего Договора, за исключением указанных в нем условий и обязательств. Вопросы, не
                    урегулированные настоящим Договором, регулируются в соответствии с законодательством Кыргызской
                    Республики.
                </p>
                <p>12.2. В случае возникновения споров и разногласий Стороны приложат все усилия, чтобы устранить их
                    путём
                    переговоров или в претензионном порядке, установленном настоящим Договором и законодательством
                    Кыргызской Республики.
                </p>
                <p>7. АДРЕС И РЕКВИЗИТЫ ИСПОЛНИТЕЛЯ</p>
                <p>
                    Исполнитель:<br>
                    Общество с Ограниченной ответственностью «Биток плюс»<br>
                    Адрес: Кыргызская Республика, город Бишкек,<br>
                    улица Усенбаева, дом 138а, офис 3<br>
                    Банк: ОАО «РСК Банк» г.Бишкек<br>
                    Р/С: 1290043130527374<br>
                    ОКПО: 31684825<br>
                    ИНН: 00311202210498<br>
                </p>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
    data() {
        return {
        }
    },
    components: {
        Header,
        Footer
    }
}
</script>