<template>
    <Transition name="fade">
        <div class="notification" :class="notification.type" v-if="notification.message" v-on:click="closeNotification">{{ notification.message }}</div>
    </Transition>
</template>

<script>
export default {
    data() {
        return {
            notificationShow: false,
            notificationClass: '',
            notificationMessage: ''
        }
    },
    computed: {
        notification() {
            return this.$store.getters.notification
        }
    },
    watch: {
        notification() {
            return setTimeout(() => {
                this.$store.dispatch('clearNotification')
            }, 4000)
        }
    },
    methods: {
        closeNotification() {
            this.$store.dispatch('clearNotification')
        }
    }
}
</script>