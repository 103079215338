<template>
    <section class="rates">
        <div class="container">
            <div class="row">
                <template v-for="(item, index) in getMainData.currencies" :key="index">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" v-if="index++<=4">
                        <div class="rate-wrap">
                            <div class="rate-wrap-header">
                                <img :src="item.logo" :alt="item.name">
                                <b>{{ item.currency }}</b>
                                <span>{{ item.name }}</span>
                                <!-- <i></i> -->
                            </div>
                            <div class="rate-wrap-content row">
                                <div class="col-6">
                                    <h1>{{ item.rate }}$</h1>
                                    <span :class="String(item.difference).search('-') !== -1 ? 'text-danger' : 'text-success'">{{ item.difference }}$</span>
                                </div>
                                <div class="col-6 rate-chart">
                                    <apexchart type="area" width="120" height="40" :options="chartOptions" :series="rateFormat(item)"></apexchart>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </section>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'

export default {
    components: {
        apexchart: VueApexCharts,
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    toolbar: {
                        show: false
                    },
                    sparkline: {
                        enabled: true
                    },
                    parentHeightOffset: 0,
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.9,
                        colorStops: [
                            {
                                offset: 60,
                                color: "#46E3AC",
                                opacity: 0.1
                            },
                            {
                                offset: 70,
                                color: "#46E3AC",
                                opacity: 0.08
                            },
                            {
                                offset: 100,
                                color: "transparent",
                                opacity: 1
                            }

                        ]
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    show: false,
                    labels: {
                        show: false
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    }
                },
                yaxis: {
                    labels: {
                        show: false,
                    }
                },
                grid: {
                    show: false
                },
                stroke: {
                    width: 1,
                    colors: ["#46E3AC"],
                    curve: 'smooth',
                    // curve: ['smooth', 'straight', 'stepline']
                },
                tooltip: {
                    enabled: false
                }
            }
        }
    },
    computed: {
        getMainData() {
            return (this.$store.getters.getMainData)
                ? this.$store.getters.getMainData
                : this.$store.dispatch('getMainData')
        }
    },
    methods: {
        rateFormat(data) {
            const rates = []
            Object(data.rates).forEach((item, index) => {
                rates[index++] = item.price
            })
            return [{data: rates}]
        }
    }
}
</script>