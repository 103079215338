<template>
    <section class="footer">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-3 footer-email">
                    <span class="label">Напишите нам</span>
                    <a href="mailto:business@bitok.kg" class="email-link">info@bitok.kg</a>
                    <a href="tel:+996501028120" class="email-link">+996 (501) 028-120</a>
                    <div class="social-icons d-none">
                        <a href="#" target="_blank" class="instagram"></a>
                        <a href="#" target="_blank" class="whatsapp"></a>
                        <a href="#" target="_blank" class="telegram"></a>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <span class="label">Быстрые ссылки</span>
                    <div class="footer-menu">
                        <router-link to="/" class="footer-nav ease">Главная</router-link>
                        <router-link to="/about" class="footer-nav ease">О компании</router-link>
                        <router-link to="/news" class="footer-nav ease">Новости</router-link>
                        <router-link to="/faq" class="footer-nav ease">FAQ</router-link>
                        <router-link to="/contacts" class="footer-nav ease">Контакты</router-link>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <span class="label">Регистрация</span>
                    <div class="footer-menu">
                        <router-link to="?page=registration" class="footer-nav ease">Регистрация</router-link>
                        <router-link to="?page=login" class="footer-nav ease">Войти в личный кабинет</router-link>
                        <router-link to="?page=restore" class="footer-nav ease">Забыли пароль?</router-link>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <span class="label">Условия использования</span>
                    <div class="footer-menu">
                        <router-link to="/rules" class="footer-nav ease">Публичная оферта</router-link>
                        <router-link to="/privacy-policy" class="footer-nav ease">Политика
                            конфиденциальности</router-link>
                        <router-link to="/licenses" class="footer-nav ease">Лицензия</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="copyright">
        <div class="container">
            <span>&copy; 2024 Bitok Plus LLC. Все права защищены</span>
            <a href="https://t.me/navisdevs" target="_blank">Made with ❤️ by <u>NavisDevs</u></a>
        </div>
    </div>
    <div class="support-service-links">
        <a href="https://t.me/bitokkg" class="telegram" target="_blank">
            <i class="gear"></i>
            <!-- <span>Служба поддержки по тех. вопросам</span> -->
        </a>
        <a href="https://t.me/bitokkg_support" class="telegram" target="_blank">
            <!-- <span>Служба поддержки по фин. вопросам</span> -->
        </a>
    </div>
</template>