export default {
    state: {
        profile: '',
        actions: ''
    },
    mutations: {
        
        setProfile(state, payload) {
            state.profile = payload.profile
        },
        setActionList(state, payload) {
            state.actions = payload.action
        }
    },
    actions: {
        async getProfile({dispatch, commit}) {
            dispatch('sendRequest', {method: 'GET', endpoint: '/profile/personal'})
            .then(response => response.json())
            .then(data => {
                if(data.messages === 'Access denied!') {
                    dispatch('logout')
                } else {
                    commit('setProfile', data)
                }
            })
        },
        async getActionList({dispatch, commit}) {
            dispatch('sendRequest', {method: 'GET', endpoint: '/profile/action-log'})
            .then(response => response.json())
            .then(data => {
                commit('setActionList', data)
            })
        }
    },
    getters: {
        getProfile(state) {
            return state.profile
        },
        getActionList(state) {
            return state.actions
        }
    }
}