<template>
    <section class="slider">
        <div class="container slider-main">
            <div class="row w-100">
                <div class="col-md-6 col-sm-12 slider-main-text">
                    <h1>Обмен криптовалют в Бишкеке</h1>
                    <span>Лучший сервис по продаже и покупке криптовалют (Bitcoin, USDT и др.) за доллары и сомы!</span>
                    <div class="main-btns-wrap">
                        <template v-if="isAuth">
                            <router-link to="/dashboard" class="main-button active">Перейти в личный кабинет</router-link>
                        </template>
                        <template v-else>
                            <router-link to="?page=registration" class="main-button">Регистрация</router-link>
                            <router-link to="?page=login" class="main-button active">Войти в личный кабинет</router-link>
                        </template>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="calc-wrap">
                        <div class="calc-header">
                            <div class="calc-header-text">Присоединяйтесь к нашей Telegram группе!</div>
                        </div>
                        <div class="calc-body">
                            <div class="calc-tabs">
                                <button :class="type == 'buy' && 'active'" v-on:click="type = 'buy'">Купить</button>
                                <button :class="type == 'sell' && 'active'" v-on:click="type = 'sell'">Продать</button>
                            </div>
                            <div class="calc-form" v-if="type == 'buy'">
                                <div class="form-group">
                                    <label>Вы отдаете</label>
                                    <div class="calc-input-group">
                                        <input type="number" class="calc-form-input" v-model="sendAmount" min="0" max="1000000000">
                                        <div class="calc-currency-wrap none-icon" v-on:click="modal('sendModal')"
                                            v-if="sendCurrency">
                                            <div class="calc-currency-icon">
                                                <img :src="sendCurrency.logo" :alt="sendCurrency.name">
                                            </div>
                                            <div class="calc-currency-text">
                                                <b>{{ sendCurrency.currency }}</b>
                                                <span>{{ sendCurrency.name }}</span>
                                            </div>
                                        </div>
                                        <!-- <div class="calc-currency-list" v-if="modalStatus == 'sendModal'">
                                            <template v-for="(item, index) in currencyList" :key="index">
                                                <div class="calc-currency-list-wrap"
                                                    v-on:click="selectSendCurrency(item)">
                                                    <div class="calc-currency-icon">
                                                        <img :src="item.logo" :alt="item.name">
                                                    </div>
                                                    <div class="calc-currency-text">
                                                        <b>{{ item.name }}</b>
                                                        <span>{{ item.currency }}</span>
                                                    </div>
                                                </div>
                                            </template>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Вы получаете</label>
                                    <div class="calc-input-group">
                                        <input type="number" class="calc-form-input" v-model="receiveAmount" min="0" max="1000000000">
                                        <div class="calc-currency-wrap" v-on:click="modal('receiveModal')"
                                            v-if="receiveCurrency">
                                            <div class="calc-currency-icon">
                                                <img :src="receiveCurrency.logo" :alt="receiveCurrency.name">
                                            </div>
                                            <div class="calc-currency-text">
                                                <b>{{ receiveCurrency.name }}</b>
                                                <span>1 {{ receiveCurrency.currency }} = {{ receiveCurrency.rate }} {{ sendCurrency.currency }}</span>
                                            </div>
                                        </div>
                                        <div class="calc-currency-list" v-if="modalStatus == 'receiveModal'">
                                            <template v-for="(item, index) in currencies" :key="index">
                                                <div class="calc-currency-list-wrap" v-if="item.can_buy" v-on:click="selectReceiveCurrency(item)">
                                                    <div class="calc-currency-icon">
                                                        <img :src="item.logo" :alt="item.name">
                                                    </div>
                                                    <div class="calc-currency-text">
                                                        <b>{{ item.name }}</b>
                                                        <span>{{ item.currency }}</span>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <p class="badge badge-success">Комиссия на покупку {{ receiveCurrency.buy_fees }}%</p>
                                <router-link to="?page=login" class="button block lg" v-if="!isAuth">Купить сейчас</router-link>
                                <router-link to="/dashboard/buy" class="button block lg" v-else>Купить сейчас</router-link>
                            </div>
                            <div class="calc-form" v-if="type == 'sell'">
                                <div class="form-group">
                                    <label>Вы отдаете</label>
                                    <div class="calc-input-group">
                                        <input type="number" class="calc-form-input" v-model="sendAmount" min="0" max="1000000000">
                                        <div class="calc-currency-wrap" v-on:click="modal('receiveModal')"
                                            v-if="sendCurrency">
                                            <div class="calc-currency-icon">
                                                <img :src="sendCurrency.logo" :alt="sendCurrency.name">
                                            </div>
                                            <div class="calc-currency-text">
                                                <b>{{ sendCurrency.currency }}</b>
                                                <span>1 {{ sendCurrency.currency }} = {{ sendCurrency.rate }} {{ receiveCurrency.currency }}</span>
                                            </div>
                                        </div>
                                        <div class="calc-currency-list" v-if="modalStatus == 'receiveModal'">
                                            <template v-for="(item, index) in currencies" :key="index">
                                                <div class="calc-currency-list-wrap" v-if="item.can_sell" v-on:click="selectSendCurrency(item)">
                                                    <div class="calc-currency-icon">
                                                        <img :src="item.logo" :alt="item.name">
                                                    </div>
                                                    <div class="calc-currency-text">
                                                        <b>{{ item.name }}</b>
                                                        <span>{{ item.currency }}</span>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Вы получаете</label>
                                    <div class="calc-input-group">
                                        <input type="number" class="calc-form-input" v-model="receiveAmount" min="0" max="1000000000">
                                        <div class="calc-currency-wrap none-icon" v-on:click="modal('sendModal')"
                                            v-if="receiveCurrency">
                                            <div class="calc-currency-icon">
                                                <img :src="receiveCurrency.logo" :alt="receiveCurrency.name">
                                            </div>
                                            <div class="calc-currency-text">
                                                <b>{{ receiveCurrency.currency }}</b>
                                                <span>{{ receiveCurrency.name }}</span>
                                            </div>
                                        </div>
                                        <!-- <div class="calc-currency-list" v-if="modalStatus == 'sendModal'">
                                            <template v-for="(item, index) in currencyList" :key="index">
                                                <div class="calc-currency-list-wrap"
                                                    v-on:click="selectReceiveCurrency(item)">
                                                    <div class="calc-currency-icon">
                                                        <img :src="item.logo" :alt="item.name">
                                                    </div>
                                                    <div class="calc-currency-text">
                                                        <b>{{ item.name }}</b>
                                                        <span>{{ item.currency }}</span>
                                                    </div>
                                                </div>
                                            </template>
                                        </div> -->
                                    </div>
                                </div>
                                <p class="badge badge-success">Комиссия на продажу {{ sendCurrency.sell_fees }}%</p>
                                <router-link to="?page=login" class="button block lg" v-if="!isAuth">Продать сейчас</router-link>
                                <router-link to="/dashboard/sell" class="button block lg" v-else>Продать сейчас</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import USDIcon from '../../assets/img/icons/usd.png'
// import KGSIcon from '../../assets/img/icons/flag-kg.png'

export default {
    data() {
        return {
            isAuth: false,
            modalStatus: false,
            type: 'buy',
            sendAmount: 0,
            sendCurrency: '',
            receiveAmount: 0,
            receiveCurrency: '',
            currencyList: [
                {
                    logo: USDIcon,
                    name: 'US Dollar',
                    currency: 'USD'
                },
                // {
                //     logo: KGSIcon,
                //     name: 'СОМ',
                //     currency: 'KGS'
                // }
            ]
        }
    },
    mounted() {
        if (this.currencyList[0]) {
            this.sendCurrency = this.currencyList[0]
        }
        if (localStorage.getItem('isAuth')) {
            this.isAuth = true
        }
    },
    computed: {
        currencies() {
            return (this.$store.getters.getCurrencies)
                ? this.$store.getters.getCurrencies
                : this.$store.dispatch('getCurrencies')
        },
        fiat() {
            return (this.$store.getters.getFiatCurrencies)
                ? this.$store.getters.getFiatCurrencies
                : this.$store.dispatch('getFiatCurrencies')
        }
    },
    watch: {
        currencies() {
            this.sendAmount = 1000
            this.receiveCurrency = this.currencies.USDT
            this.calculate()
        },
        type() {
            if (this.type == 'buy') {
                this.sendCurrency = this.currencyList[0]
                this.receiveCurrency = this.currencies.USDT
            }
            if (this.type == 'sell') {
                this.sendCurrency = this.currencies.USDT
                this.receiveCurrency = this.currencyList[0]
            }
            this.calculate()
        },
        sendAmount() {
            if(this.sendAmount < 0) {
                this.sendAmount = 0
            }
            this.calculate()
        }
    },
    methods: {
        modal(status) {
            this.modalStatus = status
        },
        selectSendCurrency(item) {
            this.modalStatus = false
            this.sendCurrency = item
            this.calculate()
        },
        selectReceiveCurrency(item) {
            this.modalStatus = false
            this.receiveCurrency = item
            this.calculate()
        },
        calculate() {
            if (this.type == 'buy') {
                const commission = (this.sendAmount * this.receiveCurrency.buy_fees) / 100
                const result = (this.sendAmount - commission) / this.receiveCurrency.rate
                this.receiveAmount = result.toFixed(this.receiveCurrency.decimal)
            }
            if (this.type == 'sell') {
                const qunantity = this.sendAmount * this.sendCurrency.rate // amount in USDT
                const commission = (qunantity * this.sendCurrency.sell_fees) / 100 // calculate fee
                const result = (this.sendAmount * this.sendCurrency.rate) - commission// convert to currency
                this.receiveAmount = result.toFixed(this.sendCurrency.decimal)
            }
        }
    }
}
</script>