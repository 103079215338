<template>
    <Header></Header>
    <div class="container min-height padding-top-default">
        <div class="review-page z-index">
            <h1 class="title d-flex justify-content-between align-items-center">Добавить отзыв <router-link to="/reviews" class="button md-none">Вернуться назад</router-link></h1>
            <p class="text info">
                <b>Внимание!</b> Отзывы публикуются после проверки модератора
                <br>
                Минимальная длина отзыва - 50 символов.
            </p>
            <div class="row">
                <div class="col-12 col-md-12 col-lg-8 col-xl-6">
                    <div class="dashboard-personal-wrap ellipse ellipse-after">
                        <Form v-on:submit="onsubmit" :validation-schema="validate" ref="form" class="form" autocomplete="off">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <label class="form-label">ФИО</label>
                                    <div class="form-group">
                                        <Field type="text" name="fullname" class="form-input" placeholder="Введите ФИО" v-model="fullname" required />
                                        <div class="error-input-wrap">
                                            <ErrorMessage class="error-wrap" name="fullname" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <label class="form-label">E-mail</label>
                                    <div class="form-group">
                                        <Field type="email" name="email" class="form-input" placeholder="Введите E-mail" v-model="email" required />
                                        <div class="error-input-wrap">
                                            <ErrorMessage class="error-wrap" name="email" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <label class="form-label d-flex justify-content-between">Отзыв</label>
                            <div class="form-group">
                                <Field v-slot="{ field }" v-model="message" name="message">
                                    <textarea v-bind="field" class="form-input" rows="6" name="message" placeholder="Введите отзыв" v-model="message" required></textarea>
                                </Field>
                                <div class="error-input-wrap">
                                    <ErrorMessage class="error-wrap" name="message" />
                                </div>
                            </div>
                            <button class="button button-success block lg mt-2" type="submit" :disabled="loading">Отправить на проверку</button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
    data() {
        return {
            fullname: '',
            email: '',
            message: ''
        }
    },
    components: {
        Form,
        Field,
        ErrorMessage,
        Header,
        Footer
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        validate() {
            return yup.object({
                fullname: yup.string().required('Поле обязательное для заполнения'),
                email: yup.string().required('Поле обязательное для заполнения').email('Неправильный адрес электронной почты'),
                message: yup.string().required('Поле обязательное для заполнения')
            })
        }
    },
    methods: {
        onsubmit() {
            if (this.$refs.form.validate()) {
                const data = {
                    fullname: this.fullname,
                    email: this.email,
                    message: this.message
                }
                this.$store.dispatch('addReview', data)
            }
        }
    }
}
</script>