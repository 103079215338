<template>
    <Header></Header>
    <div class="container min-height padding-top-default">
        <div class="news-page z-index">
            <h1 class="title">Новости</h1>
            <div class="row">
                <div class="col-12 col-md-12 col-lg-8" v-if="NewsList">
                    <div class="news-wrap" v-for="(news, index) in NewsList.news" :key="index">
                        <span class="news-title">{{ news.name }}</span>
                        <div class="news-thumbnail">
                            <img :src="news.photo" :alt="news.name">
                        </div>                        
                        <p class="news-description">{{ news.body }}</p>
                        <router-link :to="'/news/' + news.id" class="button">Читать далее</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
    components: {
        Header,
        Footer
    },
    computed: {
        NewsList() {
            return (this.$store.getters.getNewsList)
            ? this.$store.getters.getNewsList
            : this.$store.dispatch('getNewsList')
        }
    }
}
</script>