<template>
    <Header></Header>
    <div class="container min-height padding-top-default">
        <div class="dashboard-main z-index">
            <LeftBar></LeftBar>
            <div class="right-bar">
                <router-view></router-view>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
import Header from '@/components/Header.vue'
import LeftBar from '@/components/Account/LeftBar.vue'
import Footer from '@/components/Footer.vue'

export default {
    components: {
        Header,
        LeftBar,
        Footer
    }
}
</script>