<template>
    <h1 class="dashboard-title" v-if="detail.logo && detail.name">
        <img :src="detail.logo" :alt="detail.name" class="title-icon">
        <span>Продать {{ detail.name }}</span>
    </h1>
    <p class="text success mb-0"><b>Внимание</b>, не используйте платформу для совершения незаконных операций.
        Администрация предоставит ваши данные (номера телефонов, ip-адреса и т.д.) правоохранительным органам по первому
        требованию.</p>
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-8">
            <div class="row" v-if="info">
                <div class="col-12 col-md-6">
                    <div class="dashboard-personal-wrap ellipse">
                        <div class="profile-info-column mt-0 refill">
                            <div>
                                <span>Курс</span>
                                <b>1.00 {{ currency }} = {{ detail.rate }} USDT</b>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="dashboard-personal-wrap ellipse">
                        <div class="profile-info-column mt-0 refill">
                            <div>
                                <span>Срок исполнения</span>
                                <b>Моментально</b>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="dashboard-personal-wrap ellipse">
                        <div class="profile-info-column mt-0 refill">
                            <div>
                                <span>Минимальная сумма</span>
                                <b>{{ info.min_qty }} {{ currency }}</b>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="dashboard-personal-wrap ellipse">
                        <div class="profile-info-column mt-0 refill">
                            <div>
                                <span>Комиссия</span>
                                <b>{{ info.commission }}%</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dashboard-personal-wrap ellipse ellipse-after">
                <form v-on:submit.prevent="onsubmit" class="form position-relative z-index" autocomplete="off">
                    <label class="form-label d-flex justify-content-between">Сумма к списанию <span>Баланс: <b>{{
        detail.balance }}</b> {{ currency }}</span></label>
                    <div class="form-group mb-4">
                        <input type="number" name="amount" class="form-input" step="any" placeholder="Введите сумму"
                            v-model="amount" @input="calculate" required :disabled="loading">
                    </div>
                    <label class="form-label d-flex justify-content-between">Сумма к зачислению <span>Баланс: <b>{{
        balance }}</b> USDT</span></label>
                    <div class="form-group">
                        <input type="text" name="total" class="form-input" placeholder="Сумма к зачислению"
                            :value="quantity" disabled>
                    </div>
                    <button class="button button-danger block lg mt-4" :disabled="loading">Продолжить</button>
                </form>
            </div>
        </div>
    </div>
    <div class="modal-window" v-if="modalStatus">
        <div class="modal-close" v-on:click="closeModal"></div>
        <div class="modal-content">
            <h1 class="modal-title text-center mb-3">Подтверждение платежа</h1>
            <div class="dashboard-table px-0" v-if="confirm">
                <div class="dashboard-table-row table-row-v2">
                    <div class="dashboard-table-column">Сумма к списанию</div>
                    <div class="dashboard-table-column">{{ confirm.amount }} {{ confirm.currency }}</div>
                </div>
                <div class="dashboard-table-row table-row-v2">
                    <div class="dashboard-table-column">Сумма к зачислению</div>
                    <div class="dashboard-table-column">{{ confirm.debit }} USDT</div>
                </div>
                <div class="dashboard-table-row table-row-v2">
                    <div class="dashboard-table-column">Комиссия</div>
                    <div class="dashboard-table-column">{{ confirm.commission }} USDT</div>
                </div>
            </div>
            <button class="button block lg mt-4" :disabled="loading" v-on:click="confirmSell">Подтвердить</button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            modalStatus: false,
            currency: '',
            balance: '',
            amount: '',
            quantity: ''
        }
    },
    created() {
        this.currency = this.$router.currentRoute.value.params.currency
        this.$store.dispatch('getSellDetail', this.currency)
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        profile() {
            return (this.$store.getters.getProfile)
                ? this.$store.getters.getProfile
                : this.$store.dispatch('getProfile')
        },
        detail() {
            return (this.$store.getters.getCurrencies)
                ? this.$store.getters.getCurrencies[this.currency]
                : this.$store.dispatch('getCurrencies')
        },
        info() {
            return this.$store.getters.getSellDetail
        },
        confirm() {
            return this.$store.getters.getSellConfirm
        }
    },
    watch: {
        detail() {
            if (this.detail.can_sell !== true) {
                this.$router.push('/dashboard/sell')
            } else {
                this.balance = this.$store.getters.getCurrencies.USDT.balance
            }
        },
        confirm() {
            this.showModal()
        },
        profile() {
            if (this.profile.verification.value !== '2') {
                this.$router.push('/dashboard/settings/verification')
                this.$store.dispatch('notification', { message: 'Требуется проверка KYC' })
            }
        }
    },
    methods: {
        showModal() {
            this.modalStatus = true
        },
        closeModal() {
            this.modalStatus = false
        },
        notification(message, type) {
            this.$store.dispatch('notification', { message, type })
        },
        numberFormat(number, float) {
            var string, result
            if (String(number).includes('.')) {
                string = String(number).split('.')
                result = (float > 0) ? string[0] + '.' + string[1].substring(0, float) : string[0]
            } else {
                result = number
            }
            return result
        },
        calculate() {
            if (this.amount >= parseFloat(this.info.min_qty)) {
                const commission = (this.amount * this.info.commission) / 100
                const result = (this.amount - commission) * this.detail.rate
                this.quantity = this.numberFormat(result, 2)
            } else {
                this.quantity = ''
            }
        },
        onsubmit() {
            if (this.amount < parseFloat(this.info.min_qty)) {
                this.notification('Минимальная сумма ' + this.info.min_qty + ' ' + this.currency)
            } else {
                const data = {
                    sum: this.amount,
                    currency: this.currency
                }
                this.$store.dispatch('createSell', data)
            }
        },
        confirmSell() {
            this.$store.dispatch('confirmSell', this.confirm.id)
        }
    }
}
</script>